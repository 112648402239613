<template>
<div class="login-container flex align-center justify-center">
 <div class="bg-white solid flex" id="login">
   <div>
     <img src="../assets/img/bg_2.png">
   </div>
   <div class="flex flex-direction justify-center flex-sub" id="rightForm">
      <div class="">
        <p class="text-333 text-bold">登录</p>
        <el-form id="loginForm" :model="ruleForm" :rules="rules" ref="ruleForm" label-position="left" label-width="0px">
          <el-form-item prop="admin_acc">
            <el-input
              type="text"
              v-model="ruleForm.admin_acc"
              auto-complete="off"
              placeholder="请输入账号"
            ></el-input>
          </el-form-item>
          <el-form-item prop="admin_pwd">
            <el-input
              type="password"
              v-model="ruleForm.admin_pwd"
              auto-complete="off"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item class="btn-box">
            <el-button type="primary" @click="submitLogin('ruleForm')" :class="dis?'dis':'yesBtn'">登录</el-button >
          </el-form-item>
        </el-form>
      </div>
   </div>
 </div>
</div>
</template>

<script>
import md5 from 'js-md5'
import {login} from '@/api/http'
export default {
  data() {
    return {
      dis:true,//禁用提交按钮
      ruleForm: {
        admin_acc: "",
        admin_pwd: "",
      },
      rules: {
        admin_acc: [
          { required: true, message: "请输入账号", trigger: "blur" },
          { min: 3, max: 6, message: "长度在 3 到 6 个字符", trigger: "blur" },
        ],
        admin_pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  //监听form表单用户名和密码的输入情况
  watch: {
     ruleForm:{
      handler(newV,oldV){
       if(this.ruleForm.admin_acc&&this.ruleForm.admin_acc!=''&&this.ruleForm.admin_pwd&&this.ruleForm.admin_pwd!=''){
         this.disabled_btn=false
       }else{
         this.disabled_btn=true
       }
      },
      deep: true,//深度遍历
      immediate: true//默认false，设置为true会立即执行
     }
  },
  methods: {
    submitLogin(formName) {
      this.dis = false
      let that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.admin_pwd = md5(this.ruleForm.admin_pwd)
          login(this.ruleForm).then(res=>{
            console.log('登录',res.data)
            let {code,info} = res.data
            if(code == 1){
              localStorage.setItem('yhc_admin_acc',this.ruleForm.admin_acc)
              localStorage.setItem('yhc_admin_token',res.data.data.admin_token)
              localStorage.setItem('yhc_left_currentitem',1)
              localStorage.setItem('yhc_admin_cwxData',JSON.stringify([]))
              localStorage.setItem('yhc_admin_cwdatas',JSON.stringify([]))
              that.$router.push('/index')
            }else{
              this.$message.error(info)
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  background: url("../assets/img/bg_1.png") no-repeat center;
  height: 100%;
  overflow: hidden;
  #login {
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    width: 1038px;
    height: 703px;
    background: #fff;
    border: 1px solid #eaeaea;
    img{
      width: 470px;
      height: 702px;
    }
    #rightForm{
      padding: 0 64px;
      p{
        font-size: 40px;margin-bottom: 88px;
      }
      #loginForm{
        .el-input__inner {
          border: none !important;
        }
        .btn-box {
          text-align: center;
          button{
            width: 100%;
            height: 56px;
            margin-top: 105px;
          }
          .yesBtn{
            background-color: #34793F !important;
            border-color: #34793F !important;
          }
          .dis{
            background-color: #dcdcdc!important;
            border-color:#dcdcdc!important ;
          }
        }
      }
    }
    .el-form-item input{
      font-size: 14px;
      font-weight: 400;
      color: #A3A9C6;
    }
  }
}
</style>
